<template>
    <div class="home">
        <v-card>
            <v-container fluid>
            <v-row><v-col cols="12"></v-col></v-row>
            <v-row>
                <v-col cols="12" sm="12" md="4" lg="4" xl="4" v-if="brokerMap.length > 1" class="d-flex">
                    <v-autocomplete label="Filter Broker" v-model="selectedBrokerId" :items="brokerMap"
                        item-text="broker" item-value="broker_id" auto-select-first chips clearable deletable-chips
                        small-chips></v-autocomplete>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" sm="12" md="12" lg="12" xl="12">
                    <v-tabs>
                        <v-tab v-on:click="changeTab('table')">Table</v-tab>
                        <v-tab v-on:click="changeTab('json')">Json</v-tab>
                    </v-tabs>
                </v-col>
            </v-row>

            <v-row v-if="selectedKey=='table'">

                <v-col cols="12" sm="6" md="3" v-for="(value, name, index) in config" :key="index">
                    <v-card outlined class="mx-auto" dense v-if="name == 'big_lot'">
                        <v-card-title style="border-bottom: 1px solid rgb(64, 64, 64); font-size: 16px; font-weight: normal;" class="pt-2 pb-2">
                            {{ changeDiplay(name) }}
                        </v-card-title>

                        <v-list-item-content>
                            <v-col lg="12" md="12" xs="12" cols="12">
                                <v-text-field v-model="value.big_lot" label="Big Lot" dense />
                            </v-col>
                            <v-col lg="12" md="12" xs="12" cols="12">
                                <v-text-field v-model="value.telegramId" label="Telegram ID" dense />
                            </v-col>
                        </v-list-item-content>

                        <v-card-actions>
                            <v-btn :id="index" :loading="loading" color="primary" v-on:click="updateConfig('big_lot')">
                                Update
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                    <v-card outlined class="mx-auto" dense v-if="name == 'mandate'">
                        <v-card-title style="border-bottom: 1px solid rgb(64, 64, 64); font-size: 16px; font-weight: normal;" class="pt-2 pb-2">
                            {{ changeDiplay(name) }}
                        </v-card-title>

                        <v-list-item-content>
                            <v-col lg="6" md="12" xs="12" cols="12">
                                <v-text-field  label="Lots Thres Hold" v-model="value.lotsThresHold.default" dense />
                            </v-col>
                            <v-col lg="6" md="12" xs="12" cols="12">
                                <v-text-field  label="Pnl Thres Hold" v-model="value.pnlThresHold.default" dense />
                            </v-col>
                            <v-col lg="12" md="12" xs="12" cols="12">
                                <v-text-field label="Telegram ID" v-model="value.telegramId" dense />
                            </v-col>
                        </v-list-item-content>

                        <v-card-actions>
                            <v-btn :id="index" :loading="loading" color="primary" v-on:click="updateConfig('mandate')">
                                Update
                            </v-btn>
                        </v-card-actions>
                    </v-card>

                    <v-card outlined class="mx-auto" dense v-if="name == 'fast_trade'">
                        <v-card-title style="border-bottom: 1px solid rgb(64, 64, 64); font-size: 16px; font-weight: normal;" class="pt-2 pb-2">
                            {{ changeDiplay(name) }}
                        </v-card-title>

                        <v-list-item-content>
                            <v-col lg="6" md="12" xs="12" cols="12">
                                <v-text-field label="Lots Thres Hold" v-model="value.lotsThreshold.default" dense/>
                            </v-col>
                            <v-col lg="6" md="12" xs="12" cols="12">
                                <v-text-field  label="Second Threshold" v-model="value.secondThreshold.default" dense/>
                            </v-col>
                            <v-col lg="12" md="12" xs="12" cols="12">
                                <v-text-field  label="Telegram ID" v-model="value.telegramId" dense/>
                            </v-col>
                        </v-list-item-content>

                        <v-card-actions>
                            <v-btn :id="index" :loading="loading" color="primary" v-on:click="updateConfig('fast_trade')">
                                Update
                            </v-btn>
                        </v-card-actions>
                    </v-card>

                    <v-card outlined class="mx-auto" dense v-if="name == 'large_exposure'">
                        <v-card-title style="border-bottom: 1px solid rgb(64, 64, 64); font-size: 16px; font-weight: normal;" class="pt-2 pb-2">
                            {{ changeDiplay(name) }}
                        </v-card-title>

                        <v-list-item-content>
                            <v-col lg="12" md="12" xs="12" cols="12">
                                <v-text-field  label="Large Exposure Threshold" v-model="value.largeExposureThreshold[-1]" dense />
                            </v-col>
                            <v-col lg="12" md="12" xs="12" cols="12">
                                <v-text-field  label="Telegram ID" v-model="value.telegramId" dense />
                            </v-col>
                        </v-list-item-content>

                        <v-card-actions>
                            <v-btn :id="index" :loading="loading" color="primary" v-on:click="updateConfig('large_exposure')">
                                Update
                            </v-btn>
                        </v-card-actions>
                    </v-card>

                    <v-card outlined class="mx-auto" dense v-if="name == 'locking_position'">
                        <v-card-title style="border-bottom: 1px solid rgb(64, 64, 64); font-size: 16px; font-weight: normal;" class="pt-2 pb-2">
                            {{ changeDiplay(name) }}
                        </v-card-title>

                        <v-list-item-content>
                            <v-col lg="12" md="12" xs="12" cols="12">
                                <v-text-field  label="Locking Thres Hold" v-model="value.lockingThresHold.default" dense />
                            </v-col>
                            <v-col lg="12" md="12" xs="12" cols="12">
                                <v-text-field  label="Telegram ID" v-model="value.telegramId" dense />
                            </v-col>
                        </v-list-item-content>

                        <v-card-actions>
                            <v-btn :id="index" :loading="loading" color="primary" v-on:click="updateConfig('locking_position')">
                                Update
                            </v-btn>
                        </v-card-actions>
                    </v-card>

                    <v-card outlined class="mx-auto" dense v-if="name == 'profit_taker'">
                        <v-card-title style="border-bottom: 1px solid rgb(64, 64, 64); font-size: 16px; font-weight: normal;" class="pt-2 pb-2">
                            {{ changeDiplay(name) }}
                        </v-card-title>

                        <v-list-item-content>
                            <v-col lg="12" md="12" xs="12" cols="12">
                                <v-text-field  label="Threshold Map" v-model="value.thresholdMap[-1]" dense />
                            </v-col>
                            <v-col lg="12" md="12" xs="12" cols="12">
                                <v-text-field  label="Telegram ID" v-model="value.telegramId" dense />
                            </v-col>
                        </v-list-item-content>

                        <v-card-actions>
                            <v-btn :id="index" :loading="loading" color="primary" v-on:click="updateConfig('profit_taker')">
                                Update
                            </v-btn>
                        </v-card-actions>
                    </v-card>

                    <v-card outlined class="mx-auto" dense v-if="name == 'same_direction'">
                        <v-card-title style="border-bottom: 1px solid rgb(64, 64, 64); font-size: 16px; font-weight: normal;" class="pt-2 pb-2">
                            {{ changeDiplay(name) }}
                        </v-card-title>

                        <v-list-item-content>
                            <v-col lg="6" md="12" xs="12" cols="12">
                                <v-text-field label="Interval Thredshold" v-model="value.intervalThredshold.default" dense />
                            </v-col>
                            <v-col lg="6" md="12" xs="12" cols="12">
                                <v-text-field label="Number Thredshold" v-model="value.numberThredshold.default" dense />
                            </v-col>
                            <v-col lg="6" md="12" xs="12" cols="12">
                                <v-text-field label="Telegram ID" v-model="value.telegramId" dense />
                            </v-col>
                            <v-col lg="6" md="12" xs="12" cols="12">
                                <v-text-field label="Volume Thredshold" v-model="value.volumeThredshold.default" dense />
                            </v-col>
                        </v-list-item-content>

                        <v-card-actions>
                            <v-btn :id="index" :loading="loading" color="primary" v-on:click="updateConfig('same_direction')">
                                Update
                            </v-btn>
                        </v-card-actions>
                    </v-card>

                    <v-card outlined class="mx-auto" dense v-if="name == 'trade_on_credit'">
                        <v-card-title style="border-bottom: 1px solid rgb(64, 64, 64); font-size: 16px; font-weight: normal;" class="pt-2 pb-2">
                            {{ changeDiplay(name) }}
                        </v-card-title>

                        <v-list-item-content>
                            <v-col lg="12" md="12" xs="12" cols="12">
                                <v-text-field label="Telegram ID" v-model="value.telegramId" dense />
                            </v-col>
                            <v-col lg="12" md="12" xs="12" cols="12"><v-text-field style="opacity: 0;" class="pt-0 mt-0" disabled="disabled" label="tele" /></v-col>
                        </v-list-item-content>

                        <v-card-actions>
                            <v-btn :id="index" :loading="loading" color="primary" v-on:click="updateConfig('trade_on_credit')">
                                Update
                            </v-btn>
                        </v-card-actions>
                    </v-card>

                    <v-card outlined class="mx-auto" dense v-if="name == 'volume_alert'">
                        <v-card-title style="border-bottom: 1px solid rgb(64, 64, 64); font-size: 16px; font-weight: normal;" class="pt-2 pb-2">
                            {{ changeDiplay(name) }}
                        </v-card-title>

                        <v-list-item-content>
                            <v-col lg="12" md="12" xs="12" cols="12">
                                <v-text-field label="Threshold Map" v-model="value.thresholdMap[-1]" dense />
                            </v-col>
                            <v-col lg="12" md="12" xs="12" cols="12">
                                <v-text-field label="Telegram ID" v-model="value.telegramId" dense />
                            </v-col>
                        </v-list-item-content>

                        <v-card-actions>
                            <v-btn :id="index" :loading="loading" color="primary" v-on:click="updateConfig('volume_alert')">
                                Update
                            </v-btn>
                        </v-card-actions>
                    </v-card>

                    <v-card outlined class="mx-auto" dense v-if="name == 'watch_list'">
                        <v-card-title style="border-bottom: 1px solid rgb(64, 64, 64); font-size: 16px; font-weight: normal;" class="pt-2 pb-2">
                            {{ changeDiplay(name) }}
                        </v-card-title>

                        <v-list-item-content>
                            <v-col lg="12" md="12" xs="12" cols="12">
                                <v-text-field label="Watch List (Seprate by Comma)" v-model="value.watchList" dense />
                            </v-col>
                            <v-col lg="12" md="12" xs="12" cols="12">
                                <v-text-field label="Telegram ID" v-model="value.telegramId" dense />
                            </v-col>
                        </v-list-item-content>

                        <v-card-actions>
                            <v-btn :id="index" :loading="loading" color="primary" v-on:click="updateConfig('watch_list')">
                                Update
                            </v-btn>
                        </v-card-actions>
                    </v-card>

                    <v-card outlined class="mx-auto" dense v-if="name == 'withdraw_deposit'">
                        <v-card-title style="border-bottom: 1px solid rgb(64, 64, 64); font-size: 16px; font-weight: normal;" class="pt-2 pb-2">
                            {{ changeDiplay(name) }}
                        </v-card-title>

                        <v-list-item-content>
                           <v-col lg="12" md="12" xs="12" cols="12">
                                <v-text-field label="Amount Threshold" v-model="value.amount_threshold" dense />
                            </v-col>
                            <v-col lg="12" md="12" xs="12" cols="12">
                                <v-text-field label="Telegram ID" v-model="value.telegramId" dense />
                            </v-col>
                        </v-list-item-content>

                        <v-card-actions>
                            <v-btn :id="index" :loading="loading" color="primary" v-on:click="updateConfig('withdraw_deposit')">
                                Update
                            </v-btn>
                        </v-card-actions>
                    </v-card>

                </v-col>
            </v-row>

            <v-row v-if="selectedKey == 'json'" class="mt-5">
                <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                    <v-select :items="configKeys" label="Channel" v-model="jsonConfigKey"></v-select>
                    <JsonEditor ref="jsonEditor" v-model="configJson[jsonConfigKey]" />
                </v-col>
                <v-col cols="12" sm="12" md="12" lg="12" xl="12">
                    <v-btn :id="jsonConfigKey" :loading="loadingJson" class="ma-1" color="primary" plain
                        v-on:click="updateConfigJson()">
                        Update
                    </v-btn>
                </v-col>
            </v-row>
            </v-container>
        </v-card>
    </div>
</template>
<script>
import { permission } from "@components/mixins/permission";
import { helper } from "@components/mixins/helper";
import { snackbar } from "@components/mixins/snackbar";
import Breadcrumbs from "@components/Breadcrumbs";
import { mapState } from "vuex";
import * as apiAlertConfig from "@components/api/pk/alert-config";
import JsonEditor from "@components/ComponentJsonEditor";

export default {
    components: { Breadcrumbs, JsonEditor },
    mixins: [permission, helper, snackbar],
    data () {
        return {
            selectedBrokerId: -1,
            selectedBroker: "",
            frontPermission: [],
            alertChannel: [],
            loadTable: false,
            alertDataConfigSource: [],
            config: {},
            loading: false,
            loadingJson: false,
            selectedKey: "table",
            configKeys: [],
            jsonConfigKey: "big_lot",
            jsonEditorData: {},
            configJson: {}
        };
    },
    computed: {
        ...mapState(["brokerMap", "snackmsg", "snackbar", "snackcolor"])
    },
    watch: {
        /**
         * Watch on selectedBrokerId Change
         * @param  {[type]} ov [description]
         * @param  {[type]} nv [description]
         * @return {[type]}    [description]
         */
        selectedBrokerId (nv, ov) {
            this.selectedBroker = this.getBrokerNameById(nv);
            this.loadAlertConfiguration();
        },
        selectedKey (nv, ov) {
            if (
                nv == "json" &&
                typeof this.configJson[this.jsonConfigKey] === "string"
            ) {
                this.configJson[this.jsonConfigKey] = JSON.parse(
                    this.configJson[this.jsonConfigKey]
                );
            }
        },
        jsonConfigKey (nv, ov) {
            if (typeof this.configJson[nv] === "string") {
                this.configJson[nv] = JSON.parse(this.configJson[nv]);
            }
        }
    },
    methods: {
        /**
         * Format alert name
         * @param  {[type]} alertName [description]
         * @return {[type]}           [description]
         */
        upperString (alertName) {
            // remove underline and uppercase all letters
            const result = alertName.replaceAll("_", " ").toUpperCase();
            return result;
        },
        /**
         * Initial method
         * @return {[type]} [description]
         */
        loadAlertConfiguration () {
            const self = this;

            // fetch alert channel from localstorage
            const alertChannels = JSON.parse(localStorage.getItem("alertChannel"));

            self.config = {};
            self.configJson = {};
            self.configKeys = [];
            for (let i = 0; i < alertChannels.length; i++) {
                if (alertChannels[i] != "tick_check") {
                    const params = {
                        broker: self.selectedBroker,
                        alert: alertChannels[i]
                    };
                    apiAlertConfig.fetch(params).then((res) => {
                        self.$set(self.config, alertChannels[i], res.data);
                        self.$set(self.configJson, alertChannels[i], res.data);
                        self.configKeys.push(alertChannels[i]);
                    });
                }
            }
        },
        /**
         * 更新服务器alert配置
         * @param  {[type]} alertName [description]
         * @return {[type]}           [description]
         */
        updateConfig (alertName) {
            const self = this;
            this.loading = true;
            if (self.config[alertName].emailList) {
                let newEmailList = self.config[alertName].emailList;
                if (typeof newEmailList === "string") {
                    newEmailList = newEmailList.split(",");
                    self.config[alertName].emailList = newEmailList;
                }
            }
            if (self.config[alertName].watchList) {
                let newWatchList = self.config[alertName].watchList;
                console.log("Hello: ", typeof newWatchList);
                if (typeof newWatchList === "string") {
                    newWatchList = newWatchList.replaceAll("，", ",");
                    newWatchList = newWatchList.split(",");
                    newWatchList = newWatchList.filter(function (e) {
                        return e;
                    });
                    self.config[alertName].watchList = newWatchList;
                }
            }

            self.config[alertName].telegramId = Number(
                self.config[alertName].telegramId
            );
            self.config[alertName].sendEmail = Number(
                self.config[alertName].sendEmail
            );

            const newConfig = JSON.stringify(self.config[alertName]);
            const params = {
                alert: alertName,
                broker: self.selectedBroker,
                config: JSON.parse(newConfig)
            };
            apiAlertConfig.create(params).then((res) => {
                setTimeout(() => {
                    self.$store.state.snacktimeout = 2500;
                    self.$store.state.snackbar = true;
                    self.$store.state.snackcolor = "success";
                    self.$store.state.snackmsg =
                        this.upperString(alertName) +
                        " Configuration Update Successfully";
                }, 1450);
                setTimeout(() => {
                    self.loading = false;
                }, 2000);
            });
        },
        changeTab (key) {
            this.selectedKey = key;
        },
        /**
         * 更新Json内容
         */
        updateConfigJson () {
            const self = this;

            const isValidJson = self.isValidJson(
                self.configJson[self.jsonConfigKey]
            );

            if (!isValidJson) {
                self.snackBarDanger("Invalid Json");
                return;
            }

            const params = {
                alert: self.jsonConfigKey,
                broker: self.selectedBroker,
                config: JSON.parse(self.configJson[self.jsonConfigKey])
            };
            self.loadingJson = true;
            apiAlertConfig.create(params).then((res) => {
                self.snackBarSuccess("Update successfully");
                self.loadingJson = false;
            });
        },

        changeDiplay (id) {
            // console.log(id)
            return id.toLowerCase().split("_").map(function (word) {
                return word[0].toUpperCase() + word.substr(1);
            }).join(" ");
        }
    },
    mounted () {
        // 获取当前账户对应权限的broker及broker id
        const brokerPermission = this.loadDefaultBrokerData();
        this.selectedBrokerId = brokerPermission.selectedBrokerId;
        this.selectedBroker = brokerPermission.selectedBroker;

        // 调用alert configuration
        this.loadAlertConfiguration();

        console.log("Configuration: ", this.config);
    }
};
</script>
